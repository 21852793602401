<template>
  <div class="chat">
    <div class="chat__head">
      <chat-header
        :channel="channel"
        :client="client"
        :last-message="lastMessage"
        :status="status"
        :tags="tags"
        :agent="agent"
        :isFavorite="isFavorite"
      />
    </div>
    <div class="chat__body">
      <chat-conversation :client-id="clientId" :messages="messages" />
    </div>
    <div class="chat__footer">
      <chat-carousel />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import messagesAdminGql from '@/graphql/suscriptions/setMessage.gql';

const { mapState, mapMutations } = createNamespacedHelpers('chat');

export default {
  components: {
    ChatHeader: () => import('@/components/chat/ChatHeader.vue'),
    ChatConversation: () => import('@/components/chat/ChatConversation.vue'),
    ChatCarousel: () => import('@/components/chat/ChatCarousel.vue'),
  },
  props: {
    status: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      company: 'shared/getCurrentCompany',
      activeConversation: 'chat/activeConversation',
    }),
    ...mapState({
      messages: state => state.messages,
      clientId: state => state.activeClientId,
    }),
    conversation() {
      return this.activeConversation
        ? this.activeConversation
        : {};
    },
    channel() {
      return this.conversation.channel;
    },
    tags() {
      return this.conversation.tags;
    },
    lastMessage() {
      if (this.messages.length > 0) {
        return this.messages[this.messages.length - 1];
      }

      return {};
    },
    client() {
      return this.conversation.client;
    },
    isFavorite() {
      if (this.activeConversation) {
        const { client, campaign, agent } = this.activeConversation;
        const favorites = client?.favorites || [];
        return favorites
          .some(favorite => (
            favorite.campaign === campaign?.id
            && favorite.user === agent?.id
          ));
      }

      return false;
    },
    agent() {
      return this.conversation.agent;
    },
  },
  apollo: {
    $subscribe: {
      setMessageAdmin: {
        query: messagesAdminGql,
        variables() {
          return {
            companyId: this.company.id,
            conversation: this.conversation.id,
          };
        },
        result({ data }) {
          const { setMessageAdmin: message } = data;
          this.addMessageToConversation([message]);
        },
        error(err) {
          this.networkErr(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      },
    },
  },
  methods: {
    ...mapMutations(['addMessageToConversation']),
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chat/_index.scss";
</style>
