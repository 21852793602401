<template>
  <div class="loader-container">
    <overlay-loader :loading="isLoading" />
    <hcc-table
      @preview="checkConversation"
      @excel="createReportConversation"
      @pageChanged="changePage"
      @search="searchByClient"
      @copy="copyConversationId"
      :columns="columns"
      :rows="rows"
      :pagination="pagination.total > 10"
      :rowsPerPage="10"
      :actualPage="pagination.page"
      :totalPages="pagination.total"
      :disabledSearchByService="isLoading"
      :searchPlaceholder="searchPlaceholder"
      :unclickable="loadingChat"
      :messageToCopy="conversationId"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'statu-info'">
          <status-badge
            :type="props.row.status.key"
            v-tooltip="props.row.status.name"
          />
        </span>
        <span v-if="props.column.field === 'channels'">
          <channel-badge
            :type="channelKey(props.row)"
            v-tooltip="channelName(props.row)"
          />
        </span>
        <span v-if="props.column.field === 'client-info'">
          <div class="two-columns">
            <span>{{ props.row.client.name }}</span>
            <span class="two-columns__title">{{
              props.row.client.username
            }}</span>
          </div>
        </span>
        <span v-if="props.column.field === 'agent-info'">
          <div class="two-columns" v-if="props.row.agent">
            <span>{{ props.row.agent.name }}</span>
            <span class="two-columns__title">{{ props.row.agent.email }}</span>
          </div>
          <span v-else class="message-agent"> ----------------- </span>
        </span>
        <span v-if="props.column.field === 'agent-transfer-info'">
          <div class="two-columns" v-if="props.row.transfer">
            <span>{{ props.row.transfer.logUserName }}</span>
            <span class="two-columns__title">{{
              props.row.transfer.logUserEmail
            }}</span>
          </div>
          <span v-else class="message-agent"> ----------------- </span>
        </span>
        <span>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <hcc-modal
      v-if="activeClientId"
      custom-class="modal-custom"
      :width="700"
      name="preview"
      :buttons="['exit']"
      customClassBody="modal"
      @closed="clear"
    >
      <chat :status="status" />
    </hcc-modal>
    <modal-conversation-report-client
      :client="reportClient"
      :campaign="campaignClient"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import executeQuery from '@/utils/gql-api';
import getConversationMsg from '@/graphql/queries/conversations/getConversations.gql';
import HccTable from '@/components/shared/HccTable/index.vue';
import ChannelBadge from '@/components/ChannelBadge.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import Chat from '@/components/chat/Chat.vue';
import EventBus from '@/eventBus';
import ErrorHandler from '@/utils/error.handler';
import { CONVERSATION_LOAD_MESSAGES, MESSAGES_LOADED } from '@/eventTypes';

const { mapState, mapMutations } = createNamespacedHelpers('chat');

export default {
  props: {
    data: {
      require: true,
      type: Array,
      default: () => ([]),
    },
    pagination: {
      require: true,
      type: Object,
    },
    campaingId: {
      require: true,
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HccTable,
    ChannelBadge,
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    StatusBadge,
    Chat,
    OverlayLoader,
    ModalConversationReportClient: () => import('@/components/ModalConversationReportClient.vue'),
  },
  data() {
    return {
      rows: [],
      reportClient: {},
      campaignClient: '',
      loadingChat: false,
      status: {},
      conversationId: null,
    };
  },
  computed: {
    ...mapState({
      activeClientId: state => state.activeClientId,
    }),
    searchPlaceholder() {
      return this.$t('messages.search');
    },
    isLoading() {
      return this.loading || this.loadingChat;
    },
    columns() {
      return [{
        label: this.$t('messages.lastStatu'),
        field: 'statu-info',
        sortable: false,

      }, {
        label: this.$t('messages.client'),
        field: 'client-info',
      }, {
        label: this.$t('messages.agent'),
        field: 'agent-info',
      }, {
        label: this.$t('messages.agent-transfer'),
        field: 'agent-transfer-info',
      }, {
        label: this.$t('messages.created-at'),
        field: 'updatedAt',
      }, {
        label: 'Campaña',
        field: 'campaign.name',
        sortable: false,
      }, {
        label: this.$t('filter.channel'),
        field: 'channels',
        sortable: false,

      }];
    },
  },
  mounted() {
    this.lastMessageAt();
    EventBus.$on(CONVERSATION_LOAD_MESSAGES, (clientId, messageId, conversation) => {
      this.getMessages(clientId, messageId, conversation);
    });
  },
  watch: {
    data() {
      this.lastMessageAt();
    },
  },
  methods: {
    ...mapMutations([
      'setActiveClient',
      'updateConversation',
      'addMessageToConversation',
      'removeConversationState',
    ]),
    ...mapMutations({
      setLoader: 'updateConversationLoadingState',
      setFirstLoad: 'updateConversationFirstLoadState',
      setTopReach: 'updateConversationTopReachedState',
    }),
    async checkConversation(props) {
      this.loadingChat = true;
      this.conversationId = props.id;

      this.removeConversationState();
      this.setActiveClient(props.id);
      this.updateConversation(props);
      await this.getMessages(props.client.id);
      this.loadingChat = false;
      this.$nextTick(() => {
        this.status = props.status;
        this.$modal.show('preview');
      });
    },
    clear() {
      this.removeConversationState();
      this.setActiveClient('');
    },
    lastMessageAt() {
      this.rows = this.data.map(({ updatedAt, ...row }) => ({
        ...row,
        updatedAt: this.$moment(updatedAt).local().format('DD/MM/YYYY HH:mm'),
      }));
    },
    changePage(newPage) {
      if (this.pagination.page !== newPage) {
        this.$emit('pageChange', newPage);
      }
    },
    async getMessages(clientId, lastId, conversation = null) {
      if (clientId.length !== 0) {
        this.setLoader(true);

        try {
          const messages = await executeQuery(
            'getConversationMessages', getConversationMsg, {
              clientId,
              conversationId: this.conversationId || conversation,
              lastId,
              length: 20,
            },
            false,
          );

          this.addMessageToConversation(messages.data);

          if (messages.data.length > 0) {
            this.setFirstLoad(false);
          } else {
            this.setTopReach(true);
          }
        } catch (e) {
          ErrorHandler.logErrors(e);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        } finally {
          this.setLoader(false);
        }
        EventBus.$emit(MESSAGES_LOADED, clientId);
      }
    },
    createReportConversation(props) {
      this.reportClient = props.client;
      this.campaignClient = props.campaign?.id;
      this.$modal.show('download-conversation');
    },
    searchByClient({ value, key }) {
      if (key && value.length >= 3) {
        this.$emit('clientSearch', value);
      } else if (value.length === 0) {
        this.$emit('clientSearch', '');
      }
    },
    copyConversationId(row) {
      this.conversationId = row.id;
      this.$toasted.global.success(this.$t('messages.id-copy'));
    },
    channelName(props) {
      return props.channel ? props.channel.name : 'Eliminado';
    },
    channelKey(props) {
      return props.channel ? props.channel.channelType.key : '';
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_message_table.scss";
</style>
